import React from "react";
import { Link, NavLink } from "react-router-dom";
import defaultLogo from "../../Assets/Images/mfd-logo-new.png";
import applicationNameConfig from "../../applicationNameConfig";

const Header = () => {
  return (
    <>
      <header className="d-flex main-header mainTopHeader py-0">
        <Link to="/" className="logo text-decoration-none pt-2">
          <div className="d-flex align-items-center logobox">
            <img src={defaultLogo} alt="Logo" title="Logo" height="35px" />
            <span className="logo-text ps-1">
              {applicationNameConfig.APPLICATION_NAME}
            </span>
          </div>
        </Link>
        <nav className="d-flex align-items-center ms-auto">
          <nav className="navbar navbar-expand-lg py-0">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent1"
              aria-controls="navbarSupportedContent1"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="bi bi-list barIcon"></i>
            </button>
            <div
              className="collapse navbar-collapse responsiveView me-5"
              id="navbarSupportedContent1"
            >
              <ul className="navbar-nav">
                <li>
                  <Link to="/" aria-current="page" class="nav-link">
                    <span>Tools</span>
                  </Link>
                </li>

                <li>
                  <Link to="/about" aria-current="page" class="nav-link">
                    <span>About Us</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" aria-current="page" class="nav-link">
                    <span>Insights</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" aria-current="page" class="nav-link">
                    <span>Compliances</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" aria-current="page" class="nav-link">
                    <span>Contact Us</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </nav>
        <div className="d-flex align-items-center">
          <ul className="mb-0">
            <li>
              <ul className="mb-0 d-flex">
                <li className="nav-item d-flex me-0">
                  <button className="btn btn-primary btn-xs">
                    <NavLink className="btnLink" to="https://www.finnex.in/register" target="_blank">
                      Register
                    </NavLink>
                    |
                    <NavLink className="btnLink" to="https://www.finnex.in/login" target="_blank">
                      Login
                    </NavLink>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
