import React from "react";
import "./Home.scss";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Nav,
  Tab,
  Form,
} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import applicationNameConfig from "../../applicationNameConfig";
import AuthorImg1 from "./Image/kiran.jpg";
import AuthorImg2 from "./Image/arun.jpg";
import AuthorImg3 from "./Image/JayChandran.jpg";
import AuthorImg4 from "./Image/JayChandran.jpg";

import GoogleplayImg from "../../Assets/Images/play-store.png";
import AppStoreImg from "../../Assets/Images/app-store.png";
import IphoneImg from "../../Assets/Images/wealth-eye-mobile.png";

import InvestImg from "./Image/invest.jpg";
import DiversifyImg from "./Image/diversify.jpg";
import { Link, NavLink, useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="home mfdHome  mainWrapper">
      <section className="homeBanner position-relative">
        <Header />
        <Carousel fade className="text-white">
          <Carousel.Item className=" slides">
            <Container className="px-5">
              <Row className="align-items-center">
                <Col xs={12} sm={12} md={8} lg={7}>
                  <h1 className="mb-2">Unlock your Financial Freedom</h1>
                  <p className="mb-0">
                    The reason to build wealth to reach financial freedom isn't
                    to accumulate money. It's to be able to do whatever you want
                    and do whatever you like.
                  </p>
                  <p className="mb-0">
                    To become financial free, Think of Us, Think{" "}
                    {applicationNameConfig.APPLICATION_NAME} !
                  </p>
                  <p className="mb-2">
                    Connect with our Investment Specialist.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
          <Carousel.Item className=" slides">
            <Container className="px-5">
              <Row className="align-items-center">
                <Col xs={12} sm={12} md={8} lg={7}>
                  <h1 className="mb-2">Unlock your Financial Freedom</h1>
                  <p className="mb-0">
                    The reason to build wealth to reach financial freedom isn't
                    to accumulate money. It's to be able to do whatever you want
                    and do whatever you like.
                  </p>
                  <p className="mb-0">
                    To become financial free, Think of Us, Think{" "}
                    {applicationNameConfig.APPLICATION_NAME} !
                  </p>
                  <p className="mb-2">
                    Connect with our Investment Specialist.
                  </p>
                </Col>
              </Row>
            </Container>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="features-section py-3">
        <Container>
          <Row>
            <Col
              xs={6}
              md={6}
              lg={3}
              className="d-flex align-items-center py-1"
            >
              <i class="bi bi-calculator me-2"></i>
              <div>
                <h3 className="mb-0">Free</h3>
                <p className="mb-0">Analysis Tools</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={6}
              lg={3}
              className="d-flex align-items-center py-1"
            >
              <i class="bi bi-wallet2 me-3"></i>
              <div>
                <h3 className="mb-0">Over 2000</h3>
                <p className="mb-0">Assets to trade</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={6}
              lg={3}
              className="d-flex align-items-center py-1"
            >
              <i class="bi bi-journal-check me-2"></i>
              <div>
                <h3 className="mb-0">Seamless</h3>
                <p className="mb-0">Execution</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={6}
              lg={3}
              className="d-flex align-items-center py-1"
            >
              <i class="bi bi-graph-up me-3"></i>
              <div>
                <h3 className="mb-0">Timely</h3>
                <p className="mb-0">Market Updates</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-white py-5">
        <Container>
          <Row className="px-3">
            <Col xs={12} md={6} lg={6}>
              <div className="feature-box p-3 m-3 mx-2 text-center h-100">
                <h2 className="my-2">Invest Confidently</h2>
                <div className="thumbnail my-3">
                  <img src={InvestImg} alt="Invest Confidently" />
                </div>
                <p className="text-start px-lg-5">
                  Investing made easy, where ever you are.{" "}
                  {applicationNameConfig.APPLICATION_NAME} lets you stay in
                  control of your assets, make informed decisions, and achieve
                  your financial goals.
                </p>
                <Button className="btn-primary btn-md">Know More</Button>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div className="feature-box p-3 m-3 mx-2 text-center h-100">
                <h2 className="my-2">Diversify Effortlessly</h2>
                <div className="thumbnail my-3">
                  <img src={DiversifyImg} alt="Diversify Effortlessly" />
                </div>
                <p className="text-start px-lg-5">
                  Discover the power of diversification with our mutual fund
                  options. Select from large variety of mutual funds.
                </p>
                <Button className="btn-primary btn-md">Know More</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="unlocking-potential-section text-center py-5">
        <Container className="px-5">
          <Row>
            <Col>
              <h2 className="mb-2">Unlocking Your Fullest Potential</h2>
              <p className="pb-3">
                Tailored to meet every unique needs of independent MFDs
              </p>
              <Row className="my-4">
                <Col xs={12} md={6} lg={3}>
                  <div className="yellowbox mb-2 p-3">
                    <i class="bi bi-person-plus"></i>
                  </div>
                  <p>Easy Client Onboarding</p>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <div className="yellowbox mb-2 p-3">
                    <i class="bi bi-graph-up"></i>
                  </div>
                  <p>Market Insights</p>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <div className="yellowbox mb-2 p-3">
                    <i class="bi bi-person-lines-fill"></i>
                  </div>
                  <p>Portfolio Management</p>
                </Col>
                <Col xs={12} md={6} lg={3}>
                  <div className="yellowbox mb-2 p-3">
                    <i class="bi bi-upc-scan"></i>
                  </div>
                  <p>Seamless Transactions</p>
                </Col>
              </Row>
              <p>
                Get everything at one place with{" "}
                {applicationNameConfig.APPLICATION_NAME}
              </p>
              <div className="mt-2 text-center">
                <NavLink to={"https://www.finnex.in/register"} target="_blank">
                  <Button className="btn-primary btn-lg px-3">
                      Register Now
                  </Button>
                </NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="start-investment-section">
        <Container>
          <Row>
            <Col
              xs={12}
              md={12}
              lg={6}
              className="d-flex align-items-center pt-3 text-white"
            >
              <div className="mb-4">
                <h2 className="mb-3 fw-normal">
                  Start your investment TODAY !
                </h2>
                <p className="mb-0">
                  To become financially secure, Think of Us, <br />
                  Think{" "}
                  <strong>{applicationNameConfig.APPLICATION_NAME}</strong>
                </p>
                <p className="mb-0">Connect with our Investment Specialist.</p>
              </div>
            </Col>
            <Col xs={12} md={12} lg={6} className="py-2">
              {/* <BookAppointment /> */}
              <div className="book-appointment-box p-4 ms-auto">
                <h3 className="mb-1">Book a demo</h3>
                <Form>
                  <div className="form-wrapper">
                    <Row>
                      <Col xs={12} md={12} lg={12} className="required">
                        <Form.Label htmlFor="fullName">Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="fullName"
                          name="fullName"
                          maxLength="50"
                          autoComplete="off"
                          placeholder="Enter Name"
                          //   onBlur={handleBlur}
                          //   onChange={handleChange}
                          //   value={values.fullName}
                        />
                        {/* <ErrorMessage
                      name="fullName"
                      className="error"
                      component="span"
                    /> */}
                      </Col>
                      <Col xs={12} md={12} lg={12} className="required mt-2">
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter Email"
                          maxLength="50"
                          autoComplete="off"
                          //   onBlur={handleBlur}
                          //   onChange={(e) => {
                          //     handleChange(e);
                          //     setEmail(e.target.value);
                          //   }}
                          //   value={values.email}
                        />

                        {/* <ErrorMessage
                      name="email"
                      className="error"
                      component="span"
                    /> */}
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        className="mobileNumber required mt-2"
                      >
                        <Form.Label htmlFor="mobile">Mobile</Form.Label>
                        <div className="input-group">
                          <span
                            className="input-group-text p-0 px-1 plusOneNumber"
                            id="basic-addon1"
                          >
                            +91
                          </span>
                          <input
                            className="form-control"
                            name="mobile"
                            id="mobile"
                            type="text"
                            placeholder="Enter Mobile"
                            aria-autocomplete="none"
                            // value={values?.mobile}
                            // onChange={(e) => {
                            //   handleChange(e);
                            //   setMobile(e.target.value);
                            // }}
                            // onBlur={handleBlur}
                          />
                        </div>

                        {/* <ErrorMessage
                      name="mobile"
                      component="span"
                      className="error"
                    /> */}
                      </Col>
                      {/* {!isMfdOrRia && ( */}
                      <Col xs={12} md={6} lg={6} className="required mt-2">
                        <Form.Label htmlFor="type">Interest</Form.Label>
                        <select
                          id="type"
                          className="form-select form-control"
                          name="type"
                          // value={values.type}
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                        >
                          <option value="" hidden>
                            Please Select
                          </option>
                          <option value="MFD">Mutual Fund Distributor</option>
                          <option value="RIA">Investment Advisor</option>
                          <option value="RA">Research Analyst</option>
                          <option value="INVESTOR">Investor</option>
                        </select>
                        {/* <ErrorMessage
                        name="type"
                        className="error"
                        component="span"
                      /> */}
                      </Col>
                      {/* )} */}
                    </Row>

                    <Button
                      type="reset"
                      size="sm"
                      variant="secondary"
                      className="mt-3"
                      //   onClick={()=>resetForm()}
                    >
                      Clear
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      className="mt-3 me-0"
                      //   onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                    <div
                      id="submitBtn"
                      style={{ color: "#000", marginTop: "5px" }}
                    ></div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="count-section features-section py-2">
        <Container>
          <Row className="text-center">
            <Col xs={6} md={6} lg={3} className=" py-1">
              <i class="bi bi-people"></i>
              <h4 className="my-1">4,550</h4>
              <p className="mb-0">Happy Investors</p>
            </Col>
            <Col xs={6} md={6} lg={3} className=" py-1">
              <i class="bi bi-currency-rupee"></i>
              <h4 className="my-1">245 Cr.</h4>
              <p className="mb-0">Total Investments</p>
            </Col>
            <Col xs={6} md={6} lg={3} className=" py-1">
              <i class="bi bi-person-check"></i>
              <h4 className="my-1">100+</h4>
              <p className="mb-0">Expert Members</p>
            </Col>
            <Col xs={6} md={6} lg={3} className=" py-1">
              <i class="bi bi-buildings"></i>
              <h4 className="my-1">25</h4>
              <p className="mb-0">Branches</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="testimonials-section py-5">
        <Container>
          <Row>
            <Col className="text-center">
              <h2 className="medium">Our Happy Clients</h2>
              <p className="pb-3">
                Here's what our thrilled clients have to say about{" "}
                {applicationNameConfig.APPLICATION_NAME}
              </p>

              <OwlCarousel
                className="owl-theme pt-4"
                loop
                margin={30}
                items={3}
                center
              >
                <div class="item">
                  <div className="testimonial-card text-start">
                    <div className="testimonial-author d-flex">
                      <div className="author-img me-3">
                        <img src={AuthorImg1} alt="Author Photo" />
                      </div>
                      <div className="mt-2 ms-3">
                        <h3 className="mb-0">Mrs. Kiran Rao</h3>
                        <p className="mb-0">Founder - My Company Ltd.</p>
                      </div>
                    </div>
                    <p className="testimonial-content mb-0">
                      <span className="quote">"</span> Liked the service
                      provided by {applicationNameConfig.APPLICATION_NAME}. It
                      really helped me growing financially. It has made my
                      future safe & secure.{" "}
                      {applicationNameConfig.APPLICATION_NAME} has very
                      effective financial products which helps every individual
                      to grow financially.<span className="quote">"</span>
                    </p>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-card text-start">
                    <div className="testimonial-author d-flex">
                      <div className="author-img me-3">
                        <img src={AuthorImg2} alt="Author Photo" />
                      </div>
                      <div className="mt-2 ms-3">
                        <h3 className="mb-0">Mr. Arun Kumar</h3>
                        <p className="mb-0">Founder - My Company Ltd.</p>
                      </div>
                    </div>
                    <p className="testimonial-content mb-0">
                      <span className="quote">"</span> Liked the service
                      provided by {applicationNameConfig.APPLICATION_NAME}. It
                      really helped me growing financially. It has made my
                      future safe & secure.{" "}
                      {applicationNameConfig.APPLICATION_NAME} has very
                      effective financial products which helps every individual
                      to grow financially.<span className="quote">"</span>
                    </p>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-card text-start">
                    <div className="testimonial-author d-flex">
                      <div className="author-img me-3">
                        <img src={AuthorImg3} alt="Author Photo" />
                      </div>
                      <div className="mt-2 ms-3">
                        <h3 className="mb-0">Mr. Jay Kumar</h3>
                        <p className="mb-0">Founder - My Company Ltd.</p>
                      </div>
                    </div>
                    <p className="testimonial-content mb-0">
                      <span className="quote">"</span> Liked the service
                      provided by WMS. It really helped me growing financially.
                      It has made my future safe & secure. WMS has very
                      effective financial products which helps every individual
                      to grow financially.<span className="quote">"</span>
                    </p>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-card text-start">
                    <div className="testimonial-author d-flex">
                      <div className="author-img me-3">
                        <img src={AuthorImg4} alt="Author Photo" />
                      </div>
                      <div className="mt-2 ms-3">
                        <h3 className="mb-0">Mr. Jay Chandran</h3>
                        <p className="mb-0">Founder - My Company Ltd.</p>
                      </div>
                    </div>
                    <p className="testimonial-content mb-0">
                      <span className="quote">"</span> Liked the service
                      provided by WMS. It really helped me growing financially.
                      It has made my future safe & secure. WMS has very
                      effective financial products which helps every individual
                      to grow financially.<span className="quote">"</span>
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="download-app-section pt-4">
        <Container className="p-0">
          <Row className="mobileAppBanner pt-4">
            <Col
              xs={12}
              md={7}
              lg={7}
              className="d-flex align-items-center text-center"
            >
              <div className="">
                <h2 className="mb-3 white-text">
                  <span className="fw-normal">Manage your wealth</span> Anywhere
                  Anytime
                </h2>
                <p className="mb-0">
                  Access your portfolio, transactions, and insights on-the-go
                  with our user-friendly mobile app.
                </p>
                <h4 className="mt-5 mb-2 light">
                  Portfolio | Transactions | Insights
                </h4>
                <div className="mt-4">
                  <Link to="/#" className="mt-2 me-3">
                    <img src={GoogleplayImg} alt="Google Play" width={150} />
                  </Link>
                  <Link to="/#" className="mt-2 me-3">
                    <img src={AppStoreImg} alt="App Store" width={150} />
                  </Link>
                </div>
              </div>
            </Col>
            <Col xs={12} md={5} lg={5} className="pt-2 text-center">
              <img
                src={IphoneImg}
                alt="Finnex Wealth Management Mobile App"
                className="mobileImg"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
