import React, { useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { getIpAddressInfo } from "../../../redux/userSlice";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import understandingclient from "../Image/understanding-client.png";
import analysisresearch from "../Image/analysis-research.png";
import monitoring from "../Image/monitoring.png";
import "./AboutUs.scss";
import applicationNameConfig from "../../applicationNameConfig";
// import ReactGA from "react-ga4";

const AboutUs = () => {
  return (
    <div className="mainWrapper mfdHome">
      <div className="page-wrapper">
        <section className="aboutBanner pt-0">
          <Header />
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <div className="about-banner-caption text-center">
                  <h2>
                    <span className="fw-normal fw-italic">We are an</span>{" "}
                    Advisory & Wealth Management{" "}
                    <span className="fw-normal">Company</span>.
                  </h2>
                  <h1 className="my-4">
                    An Emerging name in Mutual Fund Distribution
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="about-section bg-white">
          <Container className="py-4">
            <Row className="pt-2">
              <Col xs={12} md={12} lg={12}>
                <p>
                  Using a customer-centric approach for solutions,{" "}
                  {applicationNameConfig.APPLICATION_NAME} offers a
                  comprehensive range of expertise in Equity Research & Advisory
                  services (Stocks, Mutual Funds, Bonds), Personal Financial
                  Planning and Structures Products. Our result oriented approach
                  is driven by a three-pronged approach enabled by our robust
                  Digital Technology Platform.{" "}
                </p>
              </Col>
            </Row>
            <Row className="py-3">
              <Col xs={12} md={12} lg={5} className="my-2">
                <img
                  src={understandingclient}
                  className="rounded"
                  alt="Understanding The Client"
                  title="Understanding The Client"
                />
              </Col>
              <Col xs={12} md={12} lg={7} className="my-1 ps-lg-5">
                <h3 className="light">
                  <i class="bi bi-people"></i> |{" "}
                  <span className="text-black">Understanding The Client</span>
                </h3>
                <p className="mb-0">
                  For us, the process begins with an understanding of the
                  client&apos;s behavior and preferences. We believe
                  comprehending a client dramatically improves the odds of
                  successful outcomes. Therefore, we invest considerable time in
                  understanding evolving customer needs. Multiple Personal
                  Interactions to know you better Use of Digital Platform to
                  capture the expectations.
                </p>
              </Col>
            </Row>
            <Row className="py-3">
              <Col xs={12} md={12} lg={7} className="my-1 pe-lg-5">
                <h3 className="light">
                  <i class="bi bi-book"></i> |{" "}
                  <span className="text-black">Deep Analysis & Research</span>
                </h3>
                <p className="mb-0">
                  Once we grasp customer problems, we deploy a comprehensive
                  range of expertise in process, technology and detailed
                  analysis and research to address them. We look at a mix of
                  human intervention and technology to disrupt the current
                  playing field.
                </p>
              </Col>
              <Col xs={12} md={12} lg={5} className="my-2">
                <img
                  src={analysisresearch}
                  className="rounded"
                  alt="Deep Analysis & Research"
                  title="Deep Analysis & Research"
                  width="100%"
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col xs={12} md={12} lg={5} className="my-2">
                <img
                  src={monitoring}
                  className="rounded"
                  alt="Understanding The Client"
                  title="Understanding The Client"
                  width="100%"
                />
              </Col>
              <Col xs={12} md={12} lg={7} className="my-1 ps-lg-5">
                <h3 className="light">
                  <i class="bi bi-activity"></i> |{" "}
                  <span className="text-black">Continuous Monitoring</span>
                </h3>
                <p className="mb-0">
                  Regular Meetings with our esteemed clients. Suggest timely
                  corrective actions.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="why-us-wrapper unlocking-potential-section text-center pt-2 pb-5">
          <Container className="px-5 pb-4">
            <Row>
              <Col>
                <h2 className="mb-2">
                  Why {applicationNameConfig.APPLICATION_NAME}?
                </h2>
                <p className="pb-3">
                  Empowering investors with innovative solutions & variety of
                  Products
                </p>
                <div className="why-us-box mx-auto">
                  <Row className="my-4">
                    <Col xs={12} md={6} lg={4}>
                      <div className="yellowbox mb-2 p-3">
                        <i class="bi bi-piggy-bank"></i>
                      </div>
                      <h3 className="light">Mutual Funds</h3>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <div className="yellowbox mb-2 p-3">
                        <i class="bi bi-graph-up"></i>
                      </div>
                      <h3 className="light">Stocks</h3>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <div className="yellowbox mb-2 p-3">
                        <i class="bi bi-briefcase"></i>
                      </div>
                      <h3 className="light">Bonds</h3>
                    </Col>
                  </Row>
                </div>
                <div className="mt-2 text-center">
                  <Button className="btn-primary btn-lg px-3">Know More</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
