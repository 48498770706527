import React from "react";
import "./Footer.scss";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import applicationNameConfig from "../../applicationNameConfig";

const Footer = () => {

  return (
    <footer className="main-footer">
      <div className="footer-bottom py-2">
        <Container fluid>
          <Row>
            <Col lg={3} md={4} xs={12}>
              <ul className="d-flex my-0 py-2">
                <li className="px-2 py-0">
                  <Link to="#">Company Policy</Link>
                </li>
                |
                <li className="px-2 py-0">
                  <Link to="#">FAQs</Link>
                </li>
              </ul>
            </Col>
            <Col lg={7} md={6} xs={12}>
              <div className="copyRight text-center py-2 d-flex align-items-center justify-content-center">
                {/* <div>
                  SEBI Regn. No: 
                </div>
                <div className="px-2">|</div> */}
                <div>
                  Copyright © 2024 <strong>{applicationNameConfig.APPLICATION_NAME}</strong>. All Rights Reserved.
                </div>
              </div>
            </Col>
            <Col lg={2} md={3} xs={12}>
              <ul class="social-links d-flex justify-content-end py-2 mb-0">
                  <li className="me-2">
                  <Link
                    to="#"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-facebook"></i>
                  </Link>
                </li>
                  <li className="me-2">
                    <Link
                      to="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="bi bi-twitter"></i>
                    </Link>
                  </li>
                  <li className="me-2">
                    <Link
                      to="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="bi bi-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="bi bi-linkedin"></i>
                    </Link>
                  </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
